import { Grid } from "@material-ui/core";
import React from "react";
import home from "../../assets/home.svg";
import "./home.css";

function Home() {
    return (
        <>
            <Grid
                container
                style={{ minHeight: "calc(100vh - 120px)", width: "100vw" }}
            >
                <Grid item xs={12} md={6}>
                    <div className="container image">
                        <img className="img" alt="homepage pic" src={home} />
                    </div>
                </Grid>
                <Grid item xs={12} md={6}>
                    <div className="container">
                        <div className="home-text">
                            ELabs is a student-run peer-to-peer technical
                            engagement platform. We offer a multitude of courses
                            and projects beyond the present syllabus to help you
                            grow and cope up with the ever so dynamic world. So
                            let's come together here at E Labs to excel by
                            learning from peers while bridging the immense gap
                            between academics and industry.
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}

export default Home;
