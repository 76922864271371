import { Button, Grid, CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState, useEffect, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import Input from "../../components/input/Input";
import NewSelect from "../../components/newSelect/NewSelect";
import SelectBox from "../../components/selectBox/SelectBox";
import "./feedback.css";
import eventDomainList from "../../assets/lists/eventDomain";
import Radio from "../../components/radioGroup/Radio";
import pic from "../../assets/illustration.svg";

function Feedback(props) {
    const [webinar, setWebinar] = useState({});
    const [progress, setProgress] = useState(false);
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(true);
    const [alertBar, setAlertBar] = useState({
        open: false,
        success: false,
        message: "",
    });
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertBar((cur) => ({ ...cur, open: false }));
    };
    useEffect(() => {
        if (JSON.stringify(webinar) === "{}") {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [webinar]);
    useEffect(() => {
        const fetchData = async () => {
            const apiUrl = `${process.env.REACT_APP_SERVER_URL}/webinar?id=${props.match.params.webinarId}`;
            const res = await fetch(apiUrl);
            const data = await res.json();
            if (res.status === 200) {
                if (data.data.length === 0) {
                    window.location.assign("/error");
                }
                return data.data[0];
            } else {
                window.location.assign("/error");
            }
        };
        fetchData().then((res) => {
            if (res) {
                if (
                    moment().format("LLL") >=
                        moment(new Date(res.when)).format("LLL") &&
                    moment().format("LLL") <=
                        moment(new Date(res.when)).add(4, "hours").format("LLL")
                ) {
                    setValid(true);
                } else {
                    setValid(false);
                }
                setWebinar(res);
            }
        });
    }, [props.match.params.webinarId]);

    const onSubmit = async (data) => {
        console.log(data);
        setProgress(true);
        data.newLesson = data.newLesson === "Yes" ? true : false;
        data.similarSession = data.similarSession === "Yes" ? true : false;
        console.log(data);
        const apiUrl = `${process.env.REACT_APP_SERVER_URL}/registration/feedback/${props.match.params.webinarId}`;
        console.log(apiUrl);
        const res = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const result = await res.json();
        if (res.status === 201) {
            reset({
                email: "",
                experience: "",
                newLesson: "",
                suggestions: "",
                similarSession: "",
                upcomingTopics: [],
            });
            setProgress(false);
            //TODO :: changed to a feedback success page
            window.location.assign(
                `/webinar/${props.match.params.webinarId}/feedback/success`
            );
        } else {
            setAlertBar({
                open: true,
                success: false,
                message: `${result.error}`,
            });
        }
        setProgress(false);
    };
    return (
        <>
            {loading && (
                <>
                    <div className="loading-page">
                        <div>
                            <CircularProgress />
                        </div>
                    </div>
                </>
            )}
            {!loading && (
                <Grid container style={{ minHeight: "calc(100vh - 120px)" }}>
                    <Grid item md={4} xs={12}>
                        <div className="webinar-details">
                            <div className="det webinar-illustation">
                                <img
                                    src={pic}
                                    alt="illustration"
                                    className="webianr-img"
                                />
                            </div>
                            <div className="det webinar-name">
                                {webinar.name}
                            </div>
                            <div className="det webinar-domain">
                                {webinar.domain}
                            </div>
                            <div className="det webinar-date">
                                {moment(webinar.when).format("LLL")}
                            </div>
                            <div className="det webinar-desc">
                                {webinar.desc}
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <div className="webinar-reg-wrapper">
                            {valid ? (
                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                    style={{}}
                                >
                                    <div className="webinar-reg-form">
                                        <div className="form-start">
                                            Fill up the feedback form as per
                                            your experience
                                        </div>
                                        <Input
                                            control={control}
                                            label="Email"
                                            name="email"
                                            rules={{
                                                required: "Email is required",
                                                pattern: {
                                                    value: /^[0-9]{7,8}@kiit\.ac\.in$/,
                                                    message:
                                                        "Enter a KIIT email",
                                                },
                                            }}
                                            errors={errors}
                                            variant="outlined"
                                        />
                                        <Radio
                                            name="experience"
                                            control={control}
                                            label={"How was your experience? "}
                                            items={["1", "2", "3", "4", "5"]}
                                            errors={errors}
                                            rules={{
                                                required: "Field is required",
                                            }}
                                            row
                                        />
                                        <Radio
                                            control={control}
                                            label="Did you learn something new?"
                                            name="newLesson"
                                            items={["Yes", "No"]}
                                            rules={{
                                                required: "Field is required",
                                            }}
                                            errors={errors}
                                        />
                                        <Radio
                                            control={control}
                                            label={
                                                "Would you like a more detailed session on " +
                                                webinar.domain +
                                                "?"
                                            }
                                            name="similarSession"
                                            items={["Yes", "No"]}
                                            rules={{
                                                required: "Field is required",
                                            }}
                                            errors={errors}
                                        />

                                        <NewSelect
                                            control={control}
                                            name="upcomingTopics"
                                            label={
                                                "Which topics would you like to be covered in upcoming sessions?"
                                            }
                                            options={eventDomainList}
                                            rules={{
                                                required: "Field is required",
                                            }}
                                            errors={errors}
                                        />
                                        <Input
                                            control={control}
                                            label="Your suggestions..."
                                            name="suggestions"
                                            rules={{
                                                required: "Field is required",
                                            }}
                                            multiline
                                            minRows={2}
                                            variant="outlined"
                                            errors={errors}
                                        />

                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disableElevation
                                            id="webinar-btn"
                                            disabled={progress}
                                        >
                                            {progress ? (
                                                <CircularProgress size="1.3rem" />
                                            ) : (
                                                "Submit"
                                            )}
                                        </Button>
                                    </div>
                                </form>
                            ) : (
                                <div className="invalid-form">
                                    No more accepting feedack
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
            )}
            <Snackbar
                open={alertBar.open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    severity={alertBar.success ? "success" : "error"}
                    onClose={handleClose}
                >
                    {alertBar.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default withRouter(Feedback);
