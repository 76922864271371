import React from "react";
import logo from "../../assets/elabs_logo.png";
import "./navbar.css";

function Navbar() {
    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <div className="top-logo">
                        <img src={logo} alt="" className="topAvatar" />
                    </div>
                    <div className="top-text">E LABS</div>
                </div>
                <div className="topRight"></div>
            </div>
        </div>
    );
}

export default Navbar;
