import React from "react";
import succesPic from "../../assets/feedbacksuccess.svg";
import "./feedsuccess.css";
import { FaInstagram,FaDiscord,FaYoutube, FaFacebook, FaLinkedin, FaGithub } from "react-icons/fa";
const links = {
    insta: "https://www.instagram.com/elabs.kiit/",
    github: "https://github.com/Kiit-Elabs",
    facebook: "https://www.facebook.com/kiitelabs",
    linkedin: "https://www.linkedin.com/company/kiit-elabs/",
    youtube: "https://www.youtube.com/c/ELabsKIIT",
    discord: "https://discord.gg/8MjM3ss5Pp",
};

const instaHandler = () => {window.open(`${links.insta}`);};
const githubHandler = () => {window.open(`${links.github}`);};
const fbHandler = () => {window.open(`${links.facebook}`);};
const linkedinHandler = () => {window.open(`${links.linkedin}`);};
const youtubeHandler = () => {window.open(`${links.youtube}`);};
const discordHandler = () => {window.open(`${links.discord}`);};

function Success() {
    return (
        <div className="whole-container">
            <div className="success-image">
                <img
                    src={succesPic}
                    alt={"successpic"}
                    className="success-img"
                />
            </div>
            <div className="success-text">
                Thank you for your valuable feedback.<br></br>
                Follow and connect us at:<br></br>
                <br></br>
                <div className="social-icons">  
                    <div className="insta" onClick={instaHandler}>
                    <FaInstagram className="fainstagram" style={{fontSize: '39px',color:'#bc2a8d'}} />
                    </div>
                    <div className="discord" onClick={discordHandler}>
                        <FaDiscord className="fadiscord" style={{fontSize: '39px', color:'#7289d9'}}/>
                    </div>
                    <div className="youtube" onClick={youtubeHandler}>
                        <FaYoutube className="fuckyou" style={{fontSize: '39px', color: '#FF0000'}}/>
                    </div>
                    <div className="github" onClick={githubHandler}>
                        <FaGithub className="fagitgub" style={{fontSize: '39px'}}/>
                    </div>
                    <div className="facebook" onClick={fbHandler}>
                        <FaFacebook className="fafb" style={{fontSize: '39px', color:'#4267B2'}}/>
                    </div>
                    <div className="linkedin" onClick={linkedinHandler}>
                        <FaLinkedin className="falinkedin" style={{fontSize: '39px', color:'#0077b5'}}/>
                    </div>
                </div> 
            </div>
            
        </div>
    );
}

export default Success;
