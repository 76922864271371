import React, { useEffect, useState, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import MuiAlert from "@material-ui/lab/Alert";
import { Button, Grid, CircularProgress, Snackbar } from "@material-ui/core";
import moment from "moment";

import "./certificateValidation.css";

function CertificateValidation(props) {
    const [regDet, setRegDet] = useState({ registration: {}, webinar: {} });
    const [loading, setLoading] = useState(false);
    const [isEligible, setisEligible] = useState(false);
    const [alertBar, setAlertBar] = useState({
        open: false,
        success: false,
        message: "",
    });
    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertBar((cur) => ({ ...cur, open: false }));
    };
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const apiUrl = `${process.env.REACT_APP_SERVER_URL}/certificate/validate/${props.match.params.regId}`;
            const res = await fetch(apiUrl);
            const result = await res.json();
            if (res.status === 200) {
                return result;
            } else {
                setAlertBar({
                    open: true,
                    success: false,
                    message: result.error,
                });
                window.location.assign(`/error`);
            }
        };
        fetchData().then((data) => {
            if (data !== undefined) {
                setRegDet(data);
            }
            setLoading(false);
        });
    }, [props.match.params.regId, regDet.webinarId]);

    useEffect(() => {
        console.log("Registrations ===> ", regDet);
    }, [regDet]);
    return (
        <>
            {loading && (
                <>
                    <div className="loading-page">
                        <div>
                            <CircularProgress />
                        </div>
                    </div>
                </>
            )}
            {!loading && (
                <Grid container style={{ minHeight: "calc(100vh - 120px)" }}>
                    <Grid item md={7} xs={12}>
                        {regDet.registration.certificate ? (
                            <div className="cert-img-wrapper">
                                <div className="cert-container">
                                    <img
                                        src={regDet.registration.certificateUrl}
                                        alt="certificate"
                                        className="certificate-img"
                                    />
                                </div>
                            </div>
                        ) : isEligible ? (
                            <div>Wait for the certificate to generate</div>
                        ) : (
                            <div>You are not eleigible for a certificate</div>
                        )}
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <div className="webinar-details-wrapper">
                            <div className="webinar-details-cert">
                                <div className="webinar-name">
                                    {regDet.webinar.name}
                                </div>
                                <div className="webinar-date">
                                    {moment(regDet.webinar.when).format("LLL")}
                                </div>
                                <div className="certificate-validate">
                                    This certificate above verifies that{" "}
                                    <span className="reg-name">
                                        {regDet.registration.name}
                                    </span>
                                    , Roll No. {regDet.registration.roll},
                                    successfully participated in the webinar{" "}
                                    {regDet.webinar.name} on{" "}
                                    {moment(regDet.webinar.when).format("LL")}{" "}
                                    as conducted by Team E Labs.
                                </div>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            )}
            <Snackbar
                open={alertBar.open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    severity={alertBar.success ? "success" : "error"}
                    onClose={handleClose}
                >
                    {alertBar.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default withRouter(CertificateValidation);
