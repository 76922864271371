import React from "react";
import succesPic from "../../assets/success.svg";
import "./success.css";
function Success() {
    return (
        <div className="whole-container">
            <div className="success-image">
                <img
                    src={succesPic}
                    alt={"successpic"}
                    className="success-img"
                />
            </div>
            <div className="success-text">
                Thank you for registering.<br></br>
                You will recieve an email with the details of the event soon.
            </div>
        </div>
    );
}

export default Success;
