import React from "react";
import {
    FormControl,
    FormLabel,
    RadioGroup as MuiRadioGroup,
    FormControlLabel,
    Radio as RadioBtn,
    FormHelperText,
} from "@material-ui/core";
import { Controller } from "react-hook-form";

function Radio({
    control,
    name,
    label,
    items,
    rules = {},
    defaultValue = "",
    errors = {},
    ...others
}) {
    return (
        <div className="input-container">
            <FormControl className="input-textfield">
                <FormLabel
                    id="select-label"
                    error={errors[name] ? true : false}
                >
                    {label}
                </FormLabel>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    rules={rules}
                    render={({ field }) => (
                        <MuiRadioGroup {...field} {...others} >
                            {items.map((item, index) => (
                                <FormControlLabel
                                    key={index}
                                    value={item}
                                    control={<RadioBtn />}
                                    label={item}
                                    
                                />
                            ))}
                        </MuiRadioGroup>
                    )}
                />
                {errors[name] && (
                    <FormHelperText error={errors[name] ? true : false}>
                        {errors[name].message}
                    </FormHelperText>
                )}
            </FormControl>
        </div>
    );
}

export default Radio;
