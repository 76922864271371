import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import NewFooter from "./components/footer/NewFooter";
import WebinarForm from "./pages/webianrForm/WebinarForm";
import Success from "./pages/successPage/Success";
import Home from "./pages/home/Home";
import PageNotFound from "./pages/pageNotFound/PageNotFound";
import Attendance from "./pages/attendance/Attendance";
import Feedback from "./pages/feedback/Feedback";
import CertificateValidation from "./pages/certificateValidation/CertificateValidation";
import FeedSuccess from "./pages/feedsuccess/Feedsuccess";
function App() {
    return (
        <div className="App">
            <Router>
                <Navbar />
                <div className="main-container">
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route
                            exact
                            path="/webinar/:webinarId/form"
                            component={WebinarForm}
                        />
                        <Route
                            exact
                            path="/webinar/:webinarId/attendance"
                            component={Attendance}
                        />
                        <Route
                            exact
                            path="/webinar/:webinarId/feedback"
                            component={Feedback}
                        />
                        <Route
                            exact
                            path="/webinar/:webinarId/feedback/success"
                            component={FeedSuccess}
                        />
                        <Route
                            exact
                            path="/webinar/:webinarId/:regId/success"
                            component={Success}
                        />
                        <Route
                            exact
                            path="/validate/cerificate/:regId"
                            component={CertificateValidation}
                        />

                        <Route path="/*" component={PageNotFound} />
                    </Switch>
                </div>
                <NewFooter />
            </Router>
        </div>
    );
}

export default App;
