import { Button, Grid, CircularProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useState, useEffect, forwardRef } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";
import Input from "../../components/input/Input";
import SelectBox from "../../components/selectBox/SelectBox";
import "./webinarForm.css";
import sourceList from "../../assets/lists/sourceList";
import branchList from "../../assets/lists/branchList";
import Radio from "../../components/radioGroup/Radio";
import pic from "../../assets/illustration.svg";

function WebinarForm(props) {
    const [webinar, setWebinar] = useState({});
    const [progress, setProgress] = useState(false);
    const [loading, setLoading] = useState(false);
    const [valid, setValid] = useState(true);
    const [alertBar, setAlertBar] = useState({
        open: false,
        success: false,
        message: "",
    });
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setAlertBar((cur) => ({ ...cur, open: false }));
    };
    useEffect(() => {
        if (JSON.stringify(webinar) === "{}") {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [webinar]);
    useEffect(() => {
        const fetchData = async () => {
            const apiUrl = `${process.env.REACT_APP_SERVER_URL}/webinar?id=${props.match.params.webinarId}`;
            const res = await fetch(apiUrl);
            const data = await res.json();
            if (res.status === 200) {
                if (data.data.length === 0) {
                    window.location.assign("/error");
                }
                return data.data[0];
            } else {
                window.location.assign("/error");
            }
        };
        fetchData().then((res) => {
            if (res) {
                if (
                    new Date() >= new Date(res.regStart) &&
                    new Date() <= new Date(res.regEnd)
                ) {
                    setValid(true);
                } else {
                    setValid(false);
                }
                setWebinar(res);
            }
        });
    }, [props.match.params.webinarId]);

    const onSubmit = async (data) => {
        console.log(data);
        setProgress(true);
        data.priorKnowledge = data.priorKnowledge === "Yes" ? true : false;
        data.webinarId = props.match.params.webinarId;
        const apiUrl = `${process.env.REACT_APP_SERVER_URL}/registration`;
        const res = await fetch(apiUrl, {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        });
        const result = await res.json();
        if (res.status === 201) {
            localStorage.setItem(
                "elabs_reg",
                JSON.stringify({ webinarId: data.webinarId, email: data.email })
            );
            reset({
                name: "",
                roll: "",
                email: "",
                year: "",
                branch: "",
                mobile: "",
                whatsapp: "",
                priorKnowledge: "",
                source: "",
                webinarId: "",
            });
            setProgress(false);
            window.location.assign(
                `/webinar/${props.match.params.webinarId}/${result.data._id}/success`
            );
        } else {
            setAlertBar({
                open: true,
                success: false,
                message: `${result.error}`,
            });
        }
        setProgress(false);
    };
    return (
        <>
            {loading && (
                <>
                    <div className="loading-page">
                        <div>
                            <CircularProgress />
                        </div>
                    </div>
                </>
            )}
            {!loading && (
                <Grid container style={{ minHeight: "calc(100vh - 120px)" }}>
                    <Grid item md={4} xs={12}>
                        <div className="webinar-details">
                            <div className="det webinar-illustation">
                                <img
                                    src={pic}
                                    alt="illustration"
                                    className="webianr-img"
                                />
                            </div>
                            <div className="det webinar-name">
                                {webinar.name}
                            </div>
                            <div className="det webinar-domain">
                                {webinar.domain}
                            </div>
                            <div className="det webinar-date">
                                {moment(webinar.when).format("LLL")}
                            </div>
                            <div className="det webinar-desc">
                                {webinar.desc}
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <div className="webinar-reg-wrapper">
                            {valid ? (
                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                    style={{}}
                                >
                                    <div className="webinar-reg-form">
                                        <div className="form-start">
                                            Fill up the details for registering
                                            yourself for the event
                                        </div>
                                        <Input
                                            control={control}
                                            label="Name"
                                            name="name"
                                            rules={{
                                                required: "Name is required",
                                            }}
                                            errors={errors}
                                        />
                                        <Input
                                            control={control}
                                            label="Roll"
                                            name="roll"
                                            rules={{
                                                required: "Roll is required",
                                                pattern: {
                                                    value: /^[0-9]{7,8}$/,
                                                    message:
                                                        "Enter a KIIT roll",
                                                },
                                            }}
                                            errors={errors}
                                        />
                                        <Input
                                            control={control}
                                            label="Email"
                                            name="email"
                                            rules={{
                                                required: "Email is required",
                                                pattern: {
                                                    value: /^[0-9]{7,8}@kiit\.ac\.in$/,
                                                    message:
                                                        "Enter a KIIT email",
                                                },
                                            }}
                                            errors={errors}
                                        />
                                        <SelectBox
                                            name="year"
                                            label="Year of study"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: "Year is required",
                                            }}
                                            options={[
                                                "1st",
                                                "2nd",
                                                "3rd",
                                                "4th",
                                            ]}
                                        />
                                        <SelectBox
                                            name="branch"
                                            label="Branch"
                                            control={control}
                                            errors={errors}
                                            rules={{
                                                required: "Branch is required",
                                            }}
                                            options={branchList}
                                        />
                                        <Input
                                            control={control}
                                            label="Calling Number"
                                            name="mobile"
                                            rules={{
                                                required:
                                                    "Mobile number is required",
                                            }}
                                            errors={errors}
                                        />
                                        <Input
                                            control={control}
                                            label="Whatsapp"
                                            name="whatsapp"
                                            rules={{
                                                required:
                                                    "Whatsapp number is required",
                                            }}
                                            errors={errors}
                                        />
                                        <Radio
                                            control={control}
                                            name="priorKnowledge"
                                            label={
                                                "Do you have any prior knowledge on basics of " +
                                                webinar.domain +
                                                "?"
                                            }
                                            items={["Yes", "No"]}
                                            errors={errors}
                                            rules={{
                                                required: "Field is required",
                                            }}
                                        />
                                        <Radio
                                            control={control}
                                            name="source"
                                            label="From where did you get the information of this event?"
                                            items={sourceList}
                                            errors={errors}
                                            rules={{
                                                required: "Field is required",
                                            }}
                                        />
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            disableElevation
                                            id="webinar-btn"
                                            disabled={progress}
                                        >
                                            {progress ? (
                                                <CircularProgress size="1.3rem" />
                                            ) : (
                                                "Submit"
                                            )}
                                        </Button>
                                    </div>
                                </form>
                            ) : (
                                <div className="invalid-form">
                                    No more accepting registrations
                                </div>
                            )}
                        </div>
                    </Grid>
                </Grid>
            )}
            <Snackbar
                open={alertBar.open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    severity={alertBar.success ? "success" : "error"}
                    onClose={handleClose}
                >
                    {alertBar.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default withRouter(WebinarForm);
