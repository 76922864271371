import React from "react";
import errorPic from "../../assets/error.svg";
function PageNotFound() {
    return (
        <div
            style={{
                width: "100vw",
                height: "calc(100vh - 120px)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div>
                <img src={errorPic} alt="error" style={{ height: "60vh" }} />
            </div>
        </div>
    );
}

export default PageNotFound;
