import {
    MenuItem,
    Select,
    FormControl,
    FormHelperText,
    OutlinedInput,
    Checkbox,
    ListItemText,
    Chip,
    Box,
    FormLabel,
} from "@material-ui/core";
import React from "react";
import { Controller } from "react-hook-form";
import "../input/input.css";

function NewSelect({
    name,
    label,
    control,
    options,
    defaultValue = [],
    rules = {},
    errors = {},
}) {
    return (
        <div className="input-container">
            <FormControl>
                <FormLabel
                    id="multiselect-label"
                    error={errors[name] ? true : false}
                >
                    {label}
                </FormLabel>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={defaultValue}
                    rules={rules}
                    render={({ field }) => (
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="select-selected"
                            className="input-textfield"
                            multiple
                            {...field}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                                return (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            gap: 0.5,
                                        }}
                                    >
                                        {selected.map((value) => (
                                            <Chip
                                                key={value}
                                                label={value}
                                                id="multiselect-chip"
                                            />
                                        ))}
                                    </Box>
                                );
                            }}
                        >
                            {options.map((eachOption, index) => (
                                <MenuItem value={eachOption} key={index}>
                                    {eachOption}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                />

                {errors[name] && (
                    <FormHelperText error={errors[name] ? true : false}>
                        {errors[name].message}
                    </FormHelperText>
                )}
            </FormControl>
        </div>
    );
}

export default NewSelect;
