import React from "react";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";
import "./input.css";
function Input({
  type = "text",
  name,
  label,
  required = false,
  control,
  defaultValue = "",
  rules = {},
  errors = {},
  ...others
}) {
  return (
    <div className="input-container">
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ field }) => (
          <TextField
            type={type}
            label={label}
            //required={required}
            className="input-textfield"
            error={errors[name] ? true : false}
            helperText={errors[name] ? errors[name].message : ""}
            InputLabelProps={{
              id: "input-label",
            }}
            inputProps={{
              id: "input-form",
            }}
            {...field}
            {...others}
          />
        )}
      />
    </div>
  );
}

export default Input;
