const sourceList = [
  "Discord",
  "Email",
  "Whatsapp",
  "Instagram",
  "Linkedin",
  "Facebook",
  "Others",
];

export default sourceList;
