const branchList = [
  "ETC",
  "EEE",
  "EE",
  "ECS",
  "EI",
  "MECHANICAL",
  "CIVIL",
  "CSE",
  "IT",
  "CSSE",
  "CSCE",
  "BCA",
];

export default branchList;
